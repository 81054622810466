import Vue from 'vue'
import 'vant/lib/index.css'
import {
  Button,
  Form,
  Field,
  Popup,
  Uploader,
  Picker,
  Calendar,
  DatetimePicker,
  Toast,
  Notify,
  Progress,
  Tag,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  NavBar,
  CellGroup,
  Cell,
  Grid,
  GridItem,
  Image as VanImage,
  Col,
  Row,
  Empty,
  Loading,
  Overlay,
  List,
  PullRefresh,
  Divider,
  Icon,
  ActionSheet
} from 'vant'

Vue.use(Button)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Calendar)
Vue.use(DatetimePicker)
Vue.use(Form)
Vue.use(Toast)
Vue.use(Notify)
Vue.use(Progress)
Vue.use(Tag)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(NavBar)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Col)
Vue.use(Row)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Uploader)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Divider)
Vue.use(Icon)
Vue.use(ActionSheet)
