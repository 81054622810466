import axios from 'axios'
import { Notify } from 'vant'
import storage from 'store'
import store from '@/store'
import {tansParams} from '@/utils/public'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000, // request timeout
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=utf-8',
  }
})

service.interceptors.request.use(config => {
  const token = storage.get('token')
  if (token) {
    config.headers['Authorization'] = JSON.stringify({token})
  }
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

  if (config.method === 'post') {
    config.data = JSON.stringify(config.data)
  }else if(config.method === 'upload'){
    config.method = 'post'
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  return config
},
error => {
  return Promise.reject(error)
}
)

service.interceptors.response.use(response => {
  const res = response.data
  console.log(response)
  if (res.code == 401) {
    store.dispatch("clearToken").then(res => {
      Notify({ type: 'warning', message: '令牌失效,需要重启登陆!' })
      location.href = '/login';
    })
    return Promise.reject(res)
  } else if (res.code !== 1) {
    Notify({ type: 'warning', message: res.msg || '接口报错' })
    return Promise.reject(res)
  } else {
    return res
  }
},
error => {
  console.log('err' + error) // for debug
  return Promise.reject(error)
}
)

export default service
