import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: '' // 用户信息
  },
  getters: {},
  mutations: {
    SET_INFO: (state, data) => {
      state.token = data.token
      state.userInfo = data
    },
    CLEAR_TOKEN: (state) => {
      state.token = ''
      state.userInfo = ''
    },
  },
  actions: {
    setInfo ({ commit }, info) {
      storage.set('token', info.token)
      storage.set('phone', info.phone)
      storage.set('password',info.password)
      commit('SET_INFO', info)
    },
    logout ({ commit }){
      storage.remove('token')
      commit('CLEAR_TOKEN')
    },
    clearToken ({ commit }) {
      storage.remove('token')
     // storage.remove('phone')
     // storage.remove('password')
      commit('CLEAR_TOKEN')
    },
  },
  modules: {}
})
