import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'
import { checkToken} from '@/api/api'

// import QuestionnaireDetails from '@/views/questionnaireDetails'
Vue.use(VueRouter)

const routes = [
  { // 配置默认路由
    path: '/', // 路由地址
    redirect: '/login' // 重定向
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/index/LoginUser')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/index/HomePage')
  },
  {
    path: '/payState',
    name: 'payState',
    component: () => import('@/views/index/PayState')
  },
  {
    path: '/voucherPayState',
    name: 'voucherPayState',
    component: () => import('@/views/index/VoucherPayState')
  },
  {
    path: '/Voucher',
    name: 'Voucher',
    component: () => import('@/views/index/Voucher')
  },
  {
    path: '/History',
    name: 'History',
    component: () => import('@/views/index/History')
  },
  {
    path: '/CourseHistory',
    name: 'CourseHistory',
    component: () => import('@/views/index/CourseHistory')
  },
  {
    path: '/CourseDetailHistory',
    name: 'CourseDetailHistory',
    component: () => import('@/views/index/CourseDetailHistory')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    if(storage.get('token')){
      checkToken().then(res => {
       next('/home')
      }).catch(err => {
        next()
      })
    }else {
      next()
    }
  } else {
    const token = storage.get('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
