// JAVA api
import request from 'utils/request'


const apis = {
  login: '/order/ft/login',
  captcha: '/order/ft/captcha',
  getInfoBySchoolId: '/order/school/settlebalance/getInfoBySchoolId',
  schoolList: '/order/franchisee/school/list',
  creatOrder: '/order/franchisee/order/addOrder',
  addVoucherOrder: '/order/franchisee/order/addVoucherOrder',
  classRooms: '/order/franchisee/classroom/getClassroomBySchoolId',
  getManagerBySchoolId: '/order/franchisee/mobile/upcoming/getManagerBySchoolId',
  upload: '/settlement/mp/images',
  history:'/order/school/mobile/settleorder/list',
  logout: '/order/ft/logout',
  templateList: '/order/school/settleTemplate/schoolList',
  templateDetailList: '/order/school/settletemplatedetail/list',
  checkToken: '/order/ft/checkToken'
}

// 接口名称: 登陆-孔垒接口地址: http://test.api.zhihuiyuedu.cn/order/ft/login
export function login (parameter) {
  return request({
    url: apis.login,
    method: 'post',
    data: parameter
  })
}

export function checkToken(){
  return request({
    url:  apis.checkToken,
    method: 'get'
  })
}

export function logout(){
  return request({
      url:  apis.logout,
      method: 'get'
  })
}

export function upload (data) {
  return request({
    url: apis.upload,
    method: 'upload',
    data: data
  })
}


export function history (params) {
  return request({
    url: apis.history,
    method: 'get',
    params: params
  })
}

export function courseHistory (params) {
  return request({
    url: apis.templateList,
    method: 'get',
    params: params
  })
}

export function templateDetailList (params) {
  return request({
    url: apis.templateDetailList,
    method: 'get',
    params: params
  })
}


// 接口名称: 获得验证码-孔垒接口地址: http://test.api.zhihuiyuedu.cn/order/ft/captcha
export function captcha (parameter) {
  return request({
    url: apis.captcha,
    method: 'get',
    params: parameter
  })
}

// 校区列表
export function schoolList () {
  return request({
    url: apis.schoolList,
    method: 'get'
  })
}

// 创建订单
export function creatOrder (parameter) {
  return request({
    url: apis.creatOrder,
    method: 'post',
    data: parameter
  })
}

export function addVoucherOrder (parameter) {
  return request({
    url: apis.addVoucherOrder,
    method: 'post',
    data: parameter
  })
}

// 教室
export function classRoomsFromSchoolId (schoolId) {
  return request({
    url: apis.classRooms,
    method: 'post',
    data: { schoolId }
  })
}
// 接口名称: 根据校区ID获得校区余额-孔垒接口地址: http://test.api.zhihuiyuedu.cn/order/school/settlebalance/getInfoBySchoolId
export function getInfoBySchoolId (schoolId) {
  return request({
    url: apis.getInfoBySchoolId,
    method: 'post',
    data: { schoolId }
  })
}

// 接口名称: 根据校区ID获得校区管理费信息-孔垒接口地址: http://test.api.zhihuiyuedu.cn/order/franchisee/mobile/upcoming/getManagerBySchoolId
export function getManagerBySchoolId (schoolId) {
  return request({
    url: apis.getManagerBySchoolId,
    method: 'post',
    data: { schoolId }
  })
}
